.footer {
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 75px;
    padding-left: 5rem;
    padding-right: 5rem;
    box-shadow: 0 -1px 4px 1px rgba(9, 9, 9, 0.23);
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: white;
}

.footerContent {
    color: black;
    width: 20%;
    display: flex;
    text-align: center;
    justify-content: center;
}

.footerContentSplitter {
    grid-gap: 50px;
}

.footerContent .link {
    text-decoration: none;
    color: black;
}

.footerContent .link.selected {
    font-weight: bold;
}

.lootyBanner {
    height: 20px;
    width: auto;
    padding-top: 2px;
    padding-left: 5px;
}


.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .footer {
        position: absolute;
        height: 75px;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 12px;
        bottom: 0;
    }

    .footerContentSplitter {
        gap: 10px;
        flex-direction: column;
    }

    .footerContent {
        width: 30%;
    }
}