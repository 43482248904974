.navbar {
    height: 75px;
    background-color: white;
    display: flex;
    width: 100%;
    justify-content: center;
    box-shadow: 0 2px 6px 3px rgba(9, 9, 9, 0.23);
    position: relative;
    z-index: 1000;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Adjust as needed */
}

.navImageWrapper {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarGhost {
    height: 75px;
}

.navbarGhost.none {
    display: none;
}

.navs {
    display: flex;
    width: 70%;
    justify-content: space-between;

}

.nav {
    position: relative;
    cursor: pointer;
    color: var(--white);
    font-family: 'Higuen Elegant';
    letter-spacing: 1px;
    font-size: 20px;
    display: flex;
    align-items: center;
    list-style-type: none;
    text-decoration: none;
    z-index: 100;
    justify-content: center;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.navRow {
    display: flex;
    align-items: center;
    flex: 1 1 45%;
}


.nav.link {
    text-decoration: none;
}

.nav::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 20px;
    left: 0;
    background-color: black;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.nav:hover::before {
    transform: scaleX(1);
}

.nav.selected::before {
    transform: scaleX(1);
}

.navImage {
    height: 65px;
}

.noCursor {
    pointer-events: none;
}

.navDesktop {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.navMobile {
    display: none;
    flex-direction: column;
    width: 100%;
}

@media screen and (max-width: 768px) {

    .nav {
        font-size: 13px;
        height: 50px;
    }

    .navbar {
        height: 100px;
    }

    .navbarGhost {
        height: 100px;
    }

    .nav::before {
        bottom: 13px;
    }

    .navImageWrapper {
        height: 50px;
    }

    .navImage {
        height: 35px;
    }

    .navMobile {
        display: flex;
    }

    .navs {
        width: 85%;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .navImageWrapper {
        display: none;
    }

    .navRow {
        width: 100%;
        justify-content: space-evenly;
    }

    .hideOnMobile {
        display: none;
    }

    .navDesktop {
        display: none;
    }
}