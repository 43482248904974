.background {
    width: auto;
    z-index: 1;
}

.dashboardWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px - 75px);
    flex-wrap: wrap;
    grid-gap: 50px;
}

.dashboardItemButton {
    border: 1px solid gray;
    text-align: center;
    padding: 2rem;
    font-size: 2rem;
    cursor: pointer;
    background-color: #dcdcdc;
}

.dashboardItemButton:hover {
    background-color: darkgray;
    border-color: gray;
}

.dashboardItemButton:active {
    background-color: gray;
    border-color: black;
}

.eventAdderWrapper {
    min-height: calc(100vh - 100px - 75px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eventAdderWrapper button:not(:last-child) {
    margin-bottom: 1rem;
}

.eventAdderWrapper label {
    display: block;
    margin-bottom: 5px;
}

.eventAdderWrapper input {
    display: block;
    padding: 10px;
}

.eventAdderWrapper select {
    padding: 10px;
}

.eventAdderWrapper input[type="number"] {
    width: 100px;
}

.cancelButton {
    margin-top: 20px;
    margin-bottom: 20px;
}

.formRow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.formRow .input-group {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
}

.form {
    width: 60%;
}

.datePicker {
    width: calc(100% - 23px);
}

.eventTextArea {
    padding: 10px;
    height: 200px;
}

.eventErrorText {
    color: red;
}
