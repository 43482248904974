.navigation {
    z-index: 10;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    background-color: #fff;
    color: black;
    box-shadow: 0 1px 4px 1px rgba(9, 9, 9, 0.23);
    justify-content: center;
}

.navigationContent {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigation-menu, .navHeaderRow {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.navHeaderRow {
    height: 75px;
    width: 60%;
    position: fixed;
    left: 20%;
    z-index: 10;
}

.spacer {
    height: 100px;
}

.logoImageWrapper {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoImage {
    height: 83px;
    width: auto;
    float: left;
    transition: height 0.2s;
}

.logoImage.btb {
    height: 83px;
    padding-bottom: 7px;
}

.logoImage:hover {
    height: 90px;
}

.socialWrapper {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socialButton {
    height: 32px;
    width: 32px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialButton:hover {
    cursor: pointer;
}

.socialButton:focus-visible {
    outline: none;
}

.socialIcon {
    height: 26px;
    width: auto;
}

.navigation-menu .linkWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation-menu .linkWrapper.transparent .link {
    pointer-events: none;
}

.selected {
    font-weight: bold;
    text-decoration: underline;
}

[data-staticsize="home"] {
    width: 110px;
}

[data-staticsize="club"] {
    width: 110px;
}

[data-staticsize="events"] {
    width: 60px;
}

[data-staticsize="about"] {
    width: 90px;
}

[data-staticsize="contact"] {
    width: 70px;
}

[data-staticsize="homepage"] {
    width: 140px;
}

.navigation-menu .link {
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    display: block;
    color: black;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: bold;
    position: relative;
}

.navigation-menu .link::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: black;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.navigation-menu .link:hover {
    color: black;
}

.navigation-menu :not(.transparent) .link:hover::before {
    transform: scaleX(1);
}

.navigation-menu :not(.transparent) .link.selected::before {
    transform: scaleX(1);
}

.navigation-menu .instagramLink {
    color: rgb(225 48 108);
}

.navigation-menu .instagramLink::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: rgb(225 48 108);;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.navigation-menu .instagramLink:hover {
    color: rgb(225 48 108);
}

.navigation-menu .instagramLink:hover::before {
    transform: scaleX(1);
}

.transparent .link {
    color: transparent;
}

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #004C6F;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 35px;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: #004C6F;
}

.navExtended {
    height: 400px;
}

.hamburgerIcon {
    height: 20px;
    width: 20px;
}

.selectedTabWrapper {
    display: none;
}

@media screen and (max-width: 768px) {
    .logoImageWrapper {
        width: 60px;
        height: 60px;
        margin-left: 1rem;
    }

    .spacer {
        height: 75px;
    }

    .spacerExtended {
        height: calc(100vh - 75px);
    }

    .navigation {
        width: 100vw;
    }

    .navigationContent {
        width: 100%;
    }

    .logoImage {
        height: 60px;
    }

    .logoImage.btb {
        height: 60px;
        padding-bottom: 5px;
    }

    .hamburger {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navigation {
        height: 75px;
        justify-content: start;
    }

    .navigation-menu ul {
        display: none;
    }

    .navigation-menu ul {
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 100px);
        background-color: white;
        border-top: 1px solid black;
    }

    .navigation-menu .link {
        text-align: center;
        margin: 0;
        display: none;
    }

    .navigation-menu .link.selected {
        display: block;
    }

    .socialWrapper {
        display: none;
    }

    .navigation-menu .link {
        color: black;
        width: 100%;
        padding: 1.5rem 0;
    }

    .navigation-menu .link:hover {
        background-color: #eee;
    }

    .navigation-menu.expanded ul {
        display: block;
    }

    .navigation-menu.expanded {
        flex-direction: column;
    }

    .navigationContent.expanded {
        height: calc(100vh - 100px);
        flex-direction: column;
        position: fixed;
        justify-content: start;
        background-color: #FAF4E6;
    }

    .navigation.expanded {
        height: calc(100vh - 100px);
        box-shadow: none;
    }

    .navigation-menu.expanded .link {
        color: red;
        width: 100vw;
    }

    .navSpacer {
        height: 75px;
    }

    .navHeaderRow {
        background-color: #ffffff;
        width: 100%;
        box-shadow: 0 1px 4px 1px rgba(9, 9, 9, 0.23);
        left: 0;
    }

    .linkWrapperExtended {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 100px;
        box-shadow: 3px 3px 10px #bebebe,
        -3px -3px 10px #ffffff;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        background-color: #f8f8f8;
        animation: navbarExtension 1s ease;
    }

    .linkWrapperExtended .link {
        font-family: Montserrat;
        font-weight: bold;
        text-decoration: none;
        color: #004C6F;
        font-size: 20px;
    }

    .linkWrapperExtended.selectedLinkWrapper {
        box-shadow: inset 3px 3px 10px #bebebe,
        inset -3px -3px 10px #ffffff;
    }

    @keyframes navbarExtension {
        0% {
            transform: translateY(-1000px);
        }
        100% {
            transform: translateY(0);
        }
    }

    .navigation-menu .linkWrapper {
        display: none;
    }

    .selectedTabWrapper {
        display: flex;
        text-align: center;
        position: absolute;
        font-weight: bold;
    }

    .selectedTabWrapper .link {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 16px;
    }

    .link.selected {
        color: dimgray;
    }

    .socialIcon {
        margin-left: 1rem;
    }

    .socialButton {
        padding-bottom: 7px;
    }

}