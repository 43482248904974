
body {
    overflow-y: scroll;
}

.background {
    min-height: calc(100vh - 75px);
    background-color: #FAF4E6;
}

.bornToBeach {
    background-color: #FAF4E6;
    color: #F8F8F8;
}

.bornToBeachAlt {
    background-color: #FAF4E6;
    color: #004C6F;
}

.bornToClubMain {
    background-color: #FAF4E6;
    color: #004C6F;
}

.bornToClubAlt {
    background-color: #FAF4E6;
    color: #004C6F;
}

.beachClubContact {
    border: 2px solid #004C6F;
    color: #004C6F;
}

.eventMain {
    background-color: #44A2B8;
    color: #f8f8f8;
}

.eventAlt {
    background-color: #f8f8f8;
    color: #44A2B8;
}

.eventsContact {
    border: 2px solid #5EC2A3;
    color: #5EC2A3;
}

.card {
    display: flex;
    justify-content: center;
    height: auto;
    flex-direction: column;
}

.cardHeader {
    text-transform: uppercase;
    padding-top: 6rem;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 42px;
    text-align: center;
}

.cardHeader.close {
    padding-top: 3rem;
}

.cardHeader.beachClub {
    font-family: 'Higuen Elegant';
    color: black;
    font-weight: normal;
    font-size: 46px;
}

.sponsorBanner {
    background-color: white;
    height: 10rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 100px;
    padding-right: 100px;
}

.sponsorIcon {
    max-height: 48px;
    max-width: 160px;
}

.columnFlex {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.header {
    font-size: 40px;
    text-align: center;
    padding-top: 2rem;
}

.mapContainer {
    width: 60%;
    height: 700px;
    margin-top: 1rem;
}

.black {
    color: #004C6F;
}

.closingInfo {
    font-size: 20px;
}

.subheading {
    font-family: Higuen;
    font-size: 32px;
    text-align: center;
    padding-top: 3rem;
}

.pdfWrapper {
    height: 600px;
    width: 400px;
    margin-top: 2rem;
    background-color: transparent;
}

.rowSplit {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.rowContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.fullCardImage {
    width: 100%;
    object-fit: cover;
}

.contactWrapper {
    min-height: calc(100vh - 100px - 75px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.contactCard {
    width: 60%;
    margin: 20px;
    padding: 40px;
    border: 2px solid;
    text-align: center;
}

.contactHeader {
    font-size: 2.5rem;
    font-family: Montserrat;
    margin-bottom: 2rem;
    color: #004C6F;
    overflow-wrap: break-word;
}

.contactHeaderSubInfo {
    margin-top: 0.5rem;
    font-size: 1.5rem;
}

.eventFormWrapper {
    width: 60%;
    margin-left: 20%;
    display: flex;
}

.eventFormRow {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.eventFormRow.splitRow {
    flex-direction: row;
}

.eventFormRow .eventFormCol:first-child {
    margin-right: 10%;
}

.eventFormCol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
}

.eventFormCol input {
    width: 100%;
}

.eventFormRow textarea {
    width: 100%;
    resize: vertical;
}

.contactInfo {
    color: black;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
}

.contactInfo a {
    margin-top: 1rem;
    color: black;
    text-decoration: none;
    overflow-wrap: break-word;
}

.contactInfo a:hover {
    text-decoration: underline;
}

.infoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.infoCard {
    max-width: 1300px;
    padding: 0 50px 50px 50px;
}

.infoHeader {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 50px;
    overflow-wrap: break-word;
}

.werdePartnerButton {
    margin-top: 2rem;
    padding: 20px;
    display: flex;
    height: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #F8F8F8;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    text-decoration: none;
    color: #004C6F;
    font-weight: 750;
    font-family: Higuen;
    font-size: 1.5rem;
    border-radius: 25% / 50%;
}

.werdePartnerButton:hover {
    background-color: #F0F0F0;
}

.werdePartnerButton:active {
    background-color: #E0E0E0;
}

.splitter {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    min-height: calc(100vh - 100px - 75px);
}

.splitter .split:last-child {
    border-left: 2px solid #0d3c61;
}


.partnerWrapper {
    margin-top: 2rem;
    width: 60%;
}

.aboutWrapper {
    margin-top: 1rem;
    font-family: Montserrat;
    color: black;
    width: 60%;
    font-size: 1.1rem;
    line-height: 130%;
    margin-bottom: 2rem;
}

.subLink {
    font-size: 2.5rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #004C6F;
    transition: font-size 0.3s ease-in-out;
    padding-bottom: 2rem;

}

.reservierenButton {
    margin-top: 4rem;
    display: inline-block;
    padding: 16px 28px 22px 28px;
    font-size: 28px;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    background-color: #decd93;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Higuen Elegant';
    letter-spacing: 1px;
}

.blockInfo {
    text-align: justify;
    font-size: 22px;
}

.reservierenButton:hover {
    background-color: #CDB259;
}

.subLink:hover {
    font-size: 2.75rem;
}

.eventInstance {
    flex-shrink: 3;
    height: 100%;
    width: 100%;
    transition: height 0.5s, width 0.5s, flex-grow 0.5s, filter 5s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventInstance.selectedEvent {
    height: 100%;
    width: 100%;
    flex-grow: 2;
}

.eventInstance.selectedEvent .eventImage, .eventInstance.selectedEvent .eventImage.solo {
    filter: none;
    width: 100%;
    height: 95%;
}

.eventInstance .eventImage {
    position: relative;
    height: 90%;
    width: 90%;
    border: none;
    filter: grayscale(100%);
    object-fit: contain;
    transition: width 0.5s, filter 0.5s, height 0.5s;
}


.mainSubheading {
    font-size: 32px;
    text-align: center;
}

.eventWrapper {
    margin-top: 1rem;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.eventWrapper[data-size="half"] {
    width: 50%;
}

.eventsWrapper {
    padding: 0 20% 0 20%;
}

.eventWrapper[data-size="max"] {
    width: 100%;
}

.eventWrapper[data-size="full"] {
    width: 100%;
}

.eventWrapper .eventInstance:not(:first-child) {
    margin-top: 1rem;
}


.eventWrapper.minimized {
    min-height: 50px;
}

.pageWrapper {
    padding-bottom: 75px; /* Footer height */
    position: relative;
    min-height: 100vh;
}

.contactButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scanWrapper {
    width: 40%;
    display: flex;
    justify-content: center;
}

.split {
    margin-top: 3rem;
    display: flex;
    width: 50%;
    padding: 0 50px 0 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.split.hover:hover {
    cursor: pointer;
}

.split .cardHeader {
    padding-top: 3rem;
}

.exitHandler {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.eventDetailsWrapper {
    background-color: rgba(50, 50, 50, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    padding: 5vh 0;
}

.sneakyButton {
    text-decoration: none;
    cursor: default;
    color: black;
}

.sneakyButton span {
    text-decoration: none;
    cursor: default;
    color: inherit;
}

.eventSplit {
    display: flex;
}

.eventRowLeft {
    width: 50%;
}

.eventRowRight {
    width: 45%;
    padding-left: 5%;
}

.eventTextWrapper {
    margin-bottom: 2rem;
    word-break: break-word;
}

.eventHeaderWrapper {
    margin-bottom: 0.5rem;
    text-align: left;
}

.eventImages {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

.eventHeaderWrapper.right {
    text-align: right;
}

.eventTextWrapper {
    margin-top: 2rem;
    color: black;
    font-size: 1rem;
    font-family: Montserrat;
    line-height: 150%;
}

.infoImageWrapper {
    width: 100%;
    margin-top: 3rem;
}

.infoImage {
    object-fit: contain;
    width: 100%;
}

.eventTextWrapper p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.eventTicketLink {
    text-decoration: underline;
    color: #004C6F;
    font-size: 1rem;
    font-family: Montserrat;
}

.eventDetailsPanel {
    position: fixed;
    border: 1px solid white;
    height: calc(90vh);
    width: calc(90vw);
    background-color: #F8F8F8;
    padding: 5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.exitButton {
    position: absolute;
    right: 25px;
    top: 25px;
}

.eventRowOne, .eventRowTwo {
    margin-bottom: 2rem;
    display: flex;
    font-size: 3rem;
    color: #004C6F;
    align-items: center;
}

.eventRowTwo {
    align-items: flex-start;
    justify-content: center;
}

.eventHeader, .eventDate, .eventText, .eventBannerWrapper {
    flex: 1;
}

.eventText, .eventBanner {
    font-size: 2rem;
    color: #004C6F;
    white-space: pre-line;
}

.eventBanner {
    max-height: 700px;
    max-width: 500px;
    object-fit: scale-down;
}

.partnerRow {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginWrapper {
    height: calc(100vh - 100px - 75px);
    width: 100%;
    background-color: #FAF4E6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginForm input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.loginButton {
    margin-top: 2rem;
}

.partnerLogo {
    max-height: 90px;
    max-width: 250px;
    width: auto;
    object-fit: scale-down;
}

.eventImageDisplayRowWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.eventImageDisplayWrapper {
    height: 200px;
    width: 150px;
}

.eventImageDisplay {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.eventDisplayWrapper {
    width: 60%;
}

.eventImageDisplaySelectedImageControlWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2rem;
    align-items: center;
}

.eventImageDisplaySelectedImageWrapper {
    height: 500px;
    width: 600px;
    display: flex;
    justify-content: center;
    z-index: 0;
    align-items: center;
}

.eventImageDisplaySelectedImage {
    height: 100%;
    width: 600px;
    object-fit: scale-down;
}

.eventImageDisplaySelectedImage.invisible {
    opacity: 0;
    height: 500px;
    width: 600px;
    object-fit: scale-down;
    position: absolute;
}

.eventImageDisplaySelectedImage.invisible.left {
    transform: translateX(-600px);
}

.eventImageDisplaySelectedImage.invisible.right {
    transform: translateX(600px);

}

.eventImageDisplaySelectedImage.fullscreen, .eventImageDisplaySelectedImage.fullscreen.invisible {
    height: calc(89vh - 10rem);
    max-width: 1000px;
    object-fit: scale-down;
}

.eventImageDisplayControlButton {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    background-color: transparent;
    border: none;
    z-index: 2;
}

.eventImageDisplayFullscreen {
    height: calc(89vh - 10rem);
    width: 1000px;
    margin-bottom: 0;
}

.marginless {
    margin: 0;
}

.bigTransitioningLeft {
    animation: bigTransitioningLeft 1s ease, opacityDown 1s linear backwards;
}

.bigTransitioningRight {
    animation: bigTransitioningRight 1s ease, opacityDown 1s linear backwards;
}

.bigTransitioningLeftExtra {
    animation: bigTransitioningLeftExtra 1s ease, opacityUp 1s linear;
}

.bigTransitioningRightExtra {
    animation: bigTransitioningRightExtra 1s ease, opacityUp 1s linear;
}

@keyframes opacityDown {
    100% {
        opacity: 0;
    }
    0% {
        opacity: 100%;
    }
}

@keyframes opacityUp {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes bigTransitioningLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(600px);
    }
}

@keyframes bigTransitioningLeftExtra {
    100% {
        transform: translateX(0);
        opacity: 100%;
    }
    0% {
        transform: translateX(-600px);
        opacity: 0;
    }
}

@keyframes bigTransitioningRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-600px);
    }
}

@keyframes bigTransitioningRightExtra {
    100% {
        transform: translateX(0);
        opacity: 100%;
    }
    0% {
        transform: translateX(600px);
        opacity: 0;
    }
}

.wartungsArbeiten {
    width: 100%;
    height: 100vh;
    color: #004C6F;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.offnungsZeiten {
    margin-top: 0.5rem;
}

.offnungsZeiten td {
    padding-top: 0.5rem;
}

.offnungsZeitenTag {
    padding-right: 1rem;
}

.playerWrapper {
    height: 61%;
    margin-top: 2rem;
    max-height: 800px;
    max-width: 1200px;
    min-height: 300px;
    min-width: 600px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noEventInfo {
    text-align: center;
    font-size: 1.75rem;
}

.noEventsImage {
    width: 90%;
}

.closedText {
    margin-top: 1.5rem;
    font-size: 20px;
    font-family: Montserrat;
}

.playerWrapper iframe {
    height: 500px;
}

.react-player {
    padding-top: 56.25%;
    position: relative;
}

.react-player > div {
    position: absolute;
}

.carouselWrapper {
    padding-top: 4rem;
    width: 50%;
    height: auto;
}

.imageCarousel {
    width: 100%;
}

.arrow.right {
    right: 0
}

.photoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
}

.photoGrid img {
    aspect-ratio: 1 / 1;
    width: 100%;
    object-fit: cover;
}

.photoGridWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadImageButton {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 200px;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    z-index: 20;
    cursor: pointer;
}

.imageCarousel .carousel.carousel-slider {
    color: black;
}

.eventDateOptional {
    display: none;
}

.carouselCard {
    flex-direction: column;
    align-items: center;
}

.oeffnungszeitenImage {
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
}

.oeffnungszeitenImage img {
    max-height: 500px;
    height: auto;
    width: 100%;
    box-shadow: 3px 3px 10px #bebebe, -3px -3px 10px #ffffff;
    display: block;
}


.oeffnungszeitenImage.portrait img {
    max-height: 700px;
}

.pdfcarousel {
    margin-top: 1rem;
    box-shadow: 3px 3px 10px #bebebe,
    -3px -3px 10px #ffffff;
    padding: 10px;
}

.pdfcarousel:hover {
    box-shadow: inset 3px 3px 10px #bebebe,
    inset -3px -3px 10px #ffffff;
}

.scrollToTopButton {
    position: fixed;
    bottom: 100px;
    right: 25px;
    width: 50px;
    height: 50px;
    border-radius: 20%;
    background-color: #FAF4E6;
    color: black;
    font-size: 32px;
    border: 2px solid #004C6F;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 3px #6b6b6b,
    0 0 3px #6b6b6b;
}

.scrollToTopIcon {
    height: 32px;
    width: 32px;
}

.infoImageWrapperOptional {
    display: none;
}

.agenturSliderImage {
    filter: brightness(30%);
    height: calc(100vh - 175px);
    width: 100%;
    object-fit: cover;
}

.agenturCategoryWrapper {
    height: auto;
    width: 100%;
    display: flex;
    margin-top: 2rem;
}

.agenturCard {
    position: relative;
}

.agenturCategoryInfo {
    padding: 0 0 0 2rem;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.agenturCategoryInfo.right {
    padding: 0 2rem 0 0;
    width: 55%;
}

.agenturCategoryInfoHeader {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    font-family: Montserrat;
}

.agenturCategoryInfoHeader.right {
    text-align: center;
}

.agenturCategoryInfoHeaderTwo {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: Montserrat;
}

.agenturCategoryInfoText {
    font-family: Montserrat;
    margin-top: 2rem;
    font-size: 1.25rem;
    text-align: justify;
}

.agenturCategoryImages {
    width: 45%;
}

.agenturCategoryImagesImage {
    object-fit: cover;
    max-height: 100%;
}

.agenturSliderText {
    bottom: 20%;
    left: 10%;
    width: 80%;
    color: white;
    font-size: 1.6rem;
    position: absolute;
    z-index: 1;
    text-align: justify;
    font-family: Montserrat;
}

.agenturSliderHeader {
    font-weight: bold;
    top: 25%;
    left: 20%;
    width: 60%;
    color: white;
    font-size: 3.5rem;
    position: absolute;
    z-index: 1;
    text-align: center;
    font-family: Montserrat;
}

.agenturSecondNavbar {
    height: 60px;
    position: relative;
    background-color: #fff; /* Adjust as needed */
    border-top: 1px solid black;
    padding: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow for a subtle effect */
    transition: all 0.3s ease; /* Add a smooth transition effect */
    display: flex;
    justify-content: space-evenly;
}

.currentQrCodeWrapper {
    max-width: 100%;
}

.qrTable {
    max-width: 100%;
    overflow-x: scroll;
}

.qrTabelleRow:hover {
    background-color: rgb(200, 200, 180);
}

.agenturSecondNavbarGhost {
    height: 60px;
}

.agenturSecondNavbarGhost.none {
    display: none
}

.agenturSecondNavbar.fixed {
    position: fixed;
    top: 100px;
    width: 100%;
    z-index: 1000; /* Adjust as needed */
}

.agenturSecondNavbarInner {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;
}

.agenturAnfrageButton {
    width: 50%;
}

.anfrageButtonWrapper {
    display: flex;
    justify-content: center;
}

.agenturNavBarText {
    cursor: pointer;
    font-family: Montserrat;
    text-align: center;
    line-height: 25px;
}

.agenturNavBarText:hover {
    text-decoration: underline;
}

.agenturCategoryWrapperWrapper {
    padding-bottom: 2rem;
    margin-left: 10%;
    margin-right: 10%;
}

.qrCodeAddWrapper {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.qrCodeAddWrapper input {
    width: 250px;
}

.qrCodeListEntry {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.qrCodeListEntry p {
    width: 250px;
}

.qrCodeSendButton {
    height: 50px;
}

.eventImagePhoto {

}

.titleImage {
    max-width: 1920px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.presseTextWrapper {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateY(100px);
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s;
}

.presseTextWrapper.fade-in {
    opacity: 1;
    transform: translateY(0);
    animation: fadeIn 0.5s forwards;
}

.presseText {
    text-align: justify;
    width: 40%;
    margin-bottom: 1rem;
    font-family: Higuen;
    font-size: 20px;
    line-height: 1.75;
    font-weight: 300;
}

.beachClubEventsImage {
    display: flex;
    align-content: center;
    justify-content: center;
}

.beachClubEventsImage img {
    width: 70%;
}

.mobile {
    display: none;
}

.titleImageWrapper {
    height: 850px;
}

@media screen and (max-width: 768px) {
    .card {
        height: auto;
        width: 100vw;
    }

    .mapContainer {
        width: 90%;
        height: 500px;
        margin-top: 1rem;
    }

    .presseTextWrapper {
        transform: translateY(20px);
    }

    .reservierenButton {
        margin-top: 1rem;
        font-size: 20px;
    }

    .beachClubEventsImage img {
        width: 100%;
    }

    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }

    .agenturSecondNavbar.fixed {
        position: fixed;
        top: 75px;
        width: 100%;
        z-index: 1000; /* Adjust as needed */
    }

    .agenturCategoryImages {
        width: 100%;
        height: 300px;
    }

    .agenturCategoryImagesImage {
        height: 300px;
    }

    .agenturSecondNavbarInner div {
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
        font-size: 0.7rem;
    }

    .agenturCategoryInfo {
        width: 100%;
        padding: 2rem;
    }

    .agenturCategoryInfo.right {
        width: 100%;
        padding: 2rem;
    }

    .agenturCategoryInfoHeader {
        font-size: 1.5rem;
        text-align: center;
    }

    .agenturCategoryInfoHeaderTwo {
        font-size: 1.1rem;
        text-align: center;
    }

    .agenturCategoryInfoHeader.right {
        font-size: 1.5rem;
        text-align: center;
    }

    .agenturCategoryInfoText {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .agenturCategoryWrapperWrapper {
        margin: 0;
    }

    .agenturSecondNavbarInner {
        width: 100%;
        margin: 0;
        flex-wrap: wrap;
    }

    .agenturCategoryWrapper {
        flex-direction: column;
        height: auto;
    }

    .agenturSliderImage {
        height: 600px;
    }

    .agenturCategoryWrapper.switched {
        flex-direction: column-reverse;
        height: auto;
    }


    .agenturSliderHeader {
        top: 50px;
        font-size: 2.5rem;
    }

    .agenturSliderText {
        bottom: 75px;
        font-size: 1.1rem;
    }

    .split .cardHeader {
        padding-top: 2rem;
    }

    .subLink {
        padding-bottom: 1.5rem;
    }

    .split {
        padding: 0 25px 0 25px;
    }

    .split .eventWrapper[data-size="full"] {
        width: 100%;
    }

    .oeffnungszeitenImage img {
        width: 100%
    }

    .cardHeader {
        padding-top: 2rem;
        overflow-wrap: break-word;
        hyphens: manual;
        padding-left: 2rem;
        padding-right: 2.5rem;
    }

    .cardHeader.beachClub {
        font-size: 36px;
    }

    .presseText {
        width: 70%;
        font-size: 16px;
    }

    .splitter .split:last-child {
        border-left: none;
    }

    .playerWrapper {
        margin-bottom: 2rem;
        min-height: 100px;
        min-width: 300px;
        width: 70%;
        max-height: 300px;
        height: 100%;
    }

    .playerWrapper iframe {
        height: 200px;
    }

    .subheading {
        padding-top: 3rem;
    }

    .rowSplit {
        flex-direction: column;
        margin-bottom: 3rem;
    }

    .splitter {
        flex-direction: column;
    }

    .splitLine {
        display: none;
    }

    .split {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .carouselWrapper {
        height: auto;
        width: 90vw;
    }

    .cardHeader {
        font-size: 30px;
    }


    .header {
        font-size: 24px;
    }

    .closingInfo {
        font-size: 18px;
    }

    .subheading {
        font-size: 24px;
    }

    .carouselWrapper {
        padding-top: 3rem;
    }

    .eventFormRow.splitRow {
        flex-direction: column;
    }

    .eventFormRow .eventFormCol:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    .eventFormCol {
        width: 100%;
    }

    .contactCard {
        width: 70%;
        padding: 20px;
    }

    .contactHeader {
        font-size: 1.3rem;
        hyphens: manual;
    }

    .contactHeaderSubInfo {
        font-size: 1rem;
    }

    .infoCard {
        padding: 0 20px 20px 20px;
    }

    .eventDateOptional {
        display: block;
        color: #004C6F;
        font-size: 14px;
        font-family: Montserrat;
        margin-bottom: 0.5rem;
    }

    .eventDisplayWrapper {
        width: 80%;
    }

    .eventRowRight {
        display: none;
    }

    .infoImageWrapperOptional {
        display: block;
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .eventHeader {
        padding-left: 0;
        padding-right: 0;
    }

    .eventRowLeft {
        width: 100%;
    }

    .eventInstance .eventImage {
        filter: none;
    }

    .scanWrapper {
        width: 90%;
    }

    .eventWrapper {
        min-height: 200px;
    }

    .eventInstance {
        height: 100%;
        width: 100%;
    }

    .noEventInfo {
        font-size: 1.5rem;
    }

    .subLink {
        font-size: 30px;
    }

    .mainSubheading {
        font-size: 22px;
    }

    .eventWrapper {
        padding-top: 0;
    }

    .eventTicketLink {
        font-size: 14px;
    }

    .splitter:last-child {
        margin-bottom: 2rem;
    }

    .eventHeaderWrapper {
        font-size: 22px;
    }

    .contactInfo a {
        font-size: 1rem;
    }

    .aboutWrapper {
        font-size: 14px;
        width: 80%;
    }

    .partnerWrapper {
        width: 90%;
    }

    .photoGrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }


    .partnerRow .partnerLogo:not(:last-child) {
        margin-right: 5%;
    }

    .partnerLogo {
        max-width: 20%;
    }

    .werdePartnerButton {
        font-size: 18px;
        padding: 10px;
        height: 75px;
    }

    .eventWrapper[data-size="full"] {
        width: 90%;
    }

    .eventsWrapper {
        padding: 0;
    }

    .titleImageWrapper {
        height: 350px;
    }
}

@media screen and (max-width: 621px) {

    .agenturSecondNavbar {
        height: 80px;
        flex-wrap: wrap;
        padding: 0.25rem;
    }

    .agenturSecondNavbarGhost {
        height: 80px;
    }

}

@media screen and (max-width: 322px) {

    .agenturSecondNavbar {
        height: 105px;
    }

    .agenturSecondNavbarGhost {
        height: 105px;
    }

}
